import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';

import {
  Wrapper,
  Title,
  FormGroup,
  Label,
  Input,
  GroupWrapper,
  Textarea,
  Send,
  RadioLabel,
  Button,
  Error,
} from './styled';

class ContactForm extends Component {
  state = {
    sent: false,
  };

  preparePayload = ({
    values: { name, email, phone, location, size, message },
  }) => {
    const payload = {};

    payload.html = `
        <h3>Contact from website</h3>

        <p>
          <strong>Name: </strong>
          ${name}
        </p>
        <p>
          <strong>Email: </strong>
          ${email}
        </p>
        <p>
          <strong>Phone number: </strong>
          ${phone}
        </p>
        <p>
          <strong>Location: </strong>
          ${location}
        </p>
        <p>
          <strong>Size: </strong>
          ${size}
        </p>
        <p>
          <strong>Message:</strong>
          ${message}
        </p>
      `;

    payload.from = 'hello@joannacleaningservice.com';
    payload.to = ['joannacleaningserviceny@gmail.com'];
    payload.subject = 'Contact from website joannacleaningservice.com';
    payload.replyTo = email;

    return payload;
  };

  handleFormSubmit = async (values, { setSubmitting }) => {
    if (this.state.sent) {
      setSubmitting(false);
      return;
    }

    const payload = this.preparePayload({ values });

    try {
      await axios.post(
        'https://9lsmko5w60.execute-api.us-east-2.amazonaws.com/prod/',
        payload
      );

      this.setState({
        sent: true,
      });
    } catch (error) {
      console.log('error m8', error);
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    return (
      <Wrapper>
        <Title>Contact form</Title>

        <Formik
          initialValues={{ name: '', email: '', phone: '', size: '', location: '', message: '' }}
          validate={values => {
            console.log(values);
            let errors = {};
            if (!values.email) {
              errors.email = 'Please enter your email address';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Please enter correct email address';
            }

            if (!values.name) {
              errors.name = 'Please enter your name';
            }

            if (!values.message) {
              errors.message = 'Please enter the message';
            }

            return errors;
          }}
          onSubmit={this.handleFormSubmit}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form>
              <GroupWrapper>
                <FormGroup>
                  <Label htmlFor="name">Your name</Label>

                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {touched.name && errors.name && (
                    <Error>{errors.name}</Error>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="email">Your email</Label>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {touched.email && errors.email && (
                    <Error>{errors.email}</Error>
                  )}
                </FormGroup>
              </GroupWrapper>

              <GroupWrapper>
                <FormGroup>
                  <Label htmlFor="phone">Your phone number</Label>

                  <Input
                    type="text"
                    id="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="location">Location</Label>

                  <Input
                    type="text"
                    id="location"
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </GroupWrapper>

              <FormGroup fullWidth>
                <Label>Size of the apt (sq.ft)</Label>

                <RadioLabel>
                  <input
                    type="radio"
                    name="size"
                    value="300 - 500"
                    onChange={handleChange}
                  />
                  <Button className="radioButton">300 - 500</Button>
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="size"
                    value="500 - 700"
                    onChange={handleChange}
                  />
                  <Button className="radioButton">500 - 700</Button>
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="size"
                    value="700 - 1,000"
                    onChange={handleChange}
                  />
                  <Button className="radioButton">700 - 1,000</Button>
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="size"
                    value="1,000 - 1,400"
                    onChange={handleChange}
                  />
                  <Button className="radioButton">1,000 - 1,400</Button>
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="size"
                    value="1,400 - 2,000"
                    onChange={handleChange}
                  />
                  <Button className="radioButton">1,400 - 2,000</Button>
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="size"
                    value="2,000+"
                    onChange={handleChange}
                  />
                  <Button className="radioButton">2,000+</Button>
                </RadioLabel>
              </FormGroup>

              <FormGroup fullWidth>
                <Label htmlFor="message">Message</Label>

                <Textarea
                  id="message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {touched.message && errors.message && (
                  <Error>{errors.message}</Error>
                )}
              </FormGroup>

              <Send disabled={isSubmitting}>
                {this.state.sent
                  ? 'Your message has been sent'
                  : isSubmitting
                  ? 'Sending...'
                  : 'Send'}
              </Send>
            </Form>
          )}
        </Formik>
      </Wrapper>
    );
  }
}

export default ContactForm;

import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import SEO from 'components/Seo';
import Container from 'components/Container';

import Details from 'content/Contact/Details';
import Form from 'content/Contact/Form';
import Map from 'content/Contact/Map';

const Contact = () => (
  <Layout>
    <SEO title="Contact" />

    <Container>
      <PageTitle>Work with us</PageTitle>

      <Row>
        <Col lg={4}>
          <Details />
        </Col>
        <Col lg={7} lgOffset={1}>
          <Form />
        </Col>
      </Row>
    </Container>

    <Map />
  </Layout>
);

export default Contact;

import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.section`
  ${media.md`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `}

  ${media.sm`
    display: block;
  `}
`;

export const Info = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #474c57;

  ${media.sm`
    font-size: 14px;
    line-height: 28px;
  `}
`;

export const Label = styled.div`
  color: #ababab;
`;

export const OpeningHours = styled.div`
  border-radius: 20px;
  background-color: rgba(244, 251, 227, 0.5);
  color: #75a605;
  padding: 16px 40px 16px 30px;
  margin: 10px -30px 20px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  ${media.md`
    width: 352px;
    margin-top: 0;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 20px;
  `}

  ${media.sm`
    margin-top: 10px;
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  `}

  td:first-child {
    padding-right: 30px;
  }

  tr,
  td {
    ${media.md`
      display: block;
    `}
  }

  tr {
    & + tr {
      ${media.md`
        margin-top: 28px;
      `}
    }
  }
`;

export const Notice = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;

  ${media.md`
    margin-left: auto;
    font-size: 12px;
    line-height: 24px;
  `}

  ${media.sm`
    margin-left: 0;
  `}
`;

export const Alert = styled.div`
  color: #ef3736;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;

  ${media.md`
    margin-left: auto;
    width: 352px;
    font-size: 12px;
    line-height: 24px;
  `}

  ${media.sm`
    margin-left: 0;
  `}
`;

export const HoursTitle = styled.h2`
  margin-top: 30px;
  font-size: 18px;

  ${media.md`
    margin-top: 0;
    margin-bottom: 20px;
  `}

  ${media.sm`
    margin-top: 30px;
    margin-bottom: 0;
  `}
`;

import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.section`
  ${media.md`
    margin-top: 70px;
  `}
`;

export const GroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.sm`
    display: block;
  `}
`;

export const Title = styled.div`
  color: #474c57;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
`;

export const FormGroup = styled.div`
  width: 300px;
  margin-bottom: 32px;

  ${media.md`
    width: 320px;
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100% !important;
  `}
`;

export const Label = styled.label`
  display: block;
  color: #474c57;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #b7b9bb;
  border-radius: 20px;
  background-color: #ffffff;
  height: 40px;
  padding: 0 24px;
  color: #474c57;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;

  &:focus {
    border: 1px solid #75a605;
    outline: 0;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 80px;
  border: 1px solid #b7b9bb;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 15px 24px;
  color: #474c57;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  resize: none;

  &:focus {
    border: 1px solid #75a605;
    outline: 0;
  }
`;

export const Send = styled.button.attrs({
  type: 'submit',
})`
  font-family: Montserrat;
  border-radius: 30.5px;
  background-color: #75a605;
  box-shadow: 0 4px 8px -2px rgba(78, 112, 0, 0.41);
  border: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding: 12px 55px;
  float: right;

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) => disabled && `
    background: #DDD;
    color: #555;
    box-shadow: none;
  `}
`;

export const RadioLabel = styled.label`
  position: relative;
  display: inline-block;
  color: #474c57;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 16px;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .radioButton {
      background-color: #75a605;
      color: white;
    }
  }
`;

export const Button = styled.div`
  border: 1px solid #b7b9bb;
  border-radius: 50px;
  padding: 7px 22px;
`;

export const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

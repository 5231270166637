import React from 'react';

import { StyledMap } from './styled';

const Map = () => (
  <StyledMap
    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3029.9884229734375!2d-73.94404898426642!3d40.586010953249904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spl!2ses!4v1552289469192"
    frameborder="0"
    allowfullscreen
  />
);

export default Map;

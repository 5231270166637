import React from 'react';

import { Wrapper, Label, Info, OpeningHours, Alert, Notice, HoursTitle } from './styled';

const Details = () => (
  <Wrapper>
    <Info>
      Joanna Cleaning Service
      <br />
      11235 Brooklyn, NY
      <br />
      <br />
      <Label>Telephone:</Label>
      +1 347 549 8326
      <br />
      <Label>E-mail: </Label>
      joannacleaningserviceny@gmail.com
    </Info>

    <div>
      <HoursTitle>Office hours</HoursTitle>
      <OpeningHours>
        <table>
          <tbody>
            <tr>
              <td>Monday to Friday:</td>
              <td>9:00 am - 6:00 pm</td>
            </tr>
            <tr>
              <td>Saturday*:</td>
              <td>9:00 am - 1:00 pm</td>
            </tr>
            <tr>
              <td>Sunday:</td>
              <td>Closed</td>
            </tr>
          </tbody>
        </table>
      </OpeningHours>

      <Notice>
        * Saturday service is available for new customers only
      </Notice>

      <Alert>
        Please remember, if you cancel with less than 24 hour notice, you will be
        charged $70.
      </Alert>
    </div>
  </Wrapper>
);

export default Details;
